<template>
  <div id="documentsVault" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10">DOCUMENTOS REQUERIDOS</v-row>
      <v-row no-gutters justify="start" class="mt-3 blackList">
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="enterprise">Razón Social</label>
          <select
            name="enterprise"
            id="enterprise"
            v-model="enterprise"
            @change="cambioEmpresa()"
          >
            <option selected disabled value="0">Selecciona Empresa</option>
            <option
              v-for="enterprise in enterprises"
              :key="enterprise.id"
              v-bind:value="enterprise.id"
            >
              {{ enterprise.razonSocial }}
            </option>
          </select>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="area">Área</label>
          <select name="area" id="area" v-model="area" @change="peticionArea()">
            <option selected value="0">Sin especificar</option>
            <option v-for="area in areas" :key="area.id" v-bind:value="area.id">
              {{ area.nombreArea }}
            </option>
          </select>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="puesto">Puestos</label>
          <select
            name="puesto"
            id="puesto"
            v-model="job"
            @change="consultaConfig()"
          >
            <option selected value="0">Sin especificar</option>
            <option v-for="j in jobs" :key="j.id" v-bind:value="j.id">
              {{ j.nombrePuesto }}
            </option>
          </select>
        </v-col>
      </v-row>
      <v-row class="mt-n2" align="center" justify="center">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <table class="tablaReportes ml-12">
            <thead>
              <tr>
                <th>Nombre del Documento</th>
                <th>Requerido</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(doc, d) in documentos"
                :key="d"
                class="text-start ma-1"
              >
                <td class="text-center">
                  {{ doc.descripcionDocumento }}
                </td>
                <td>
                  <v-switch
                    v-model="doc.Requerido"
                    color="orange"
                    inset
                    :label="`${doc.Requerido ? 'Sí' : 'No'}`"
                    class="ma-2 mb-0"
                  ></v-switch>
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <button class="botonAmarillo" @click="validar()">Guardar</button>
        </v-col>
      </v-row>
    </v-container>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      dialog: false,
      confirmation: false,
      message: "",
      respuesta: "",
      selectedItem: null,
      documentos: [],
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      area: 0,
      areas: [],
      job: 0,
      jobs: [],
      DocsRequeridos: [],
      documentosAuxiliarEmpresa: [],
      documentosAuxiliarArea: [],
      catalogoDocumentos: [],
    };
  },
  methods: {
    /*Valida, Guarda/ Actualiza documentos */
    validar() {
      //console.log("requeridos del area", this.DocsRequeridos);
      this.DocsRequeridos = [];
      //console.log("valores del puesto", this.documentos);
      this.documentos.forEach((value, index) => {
        this.DocsRequeridos.push({
          DocumentoId: value.DocumentoId,
          Requerido: value.Requerido,
        });
      });
      //console.log("para actualizar o guardar", this.DocsRequeridos);
      this.show = true;
      axios
        .post(
          Server + "/documentosRequeridos",
          {
            EmpresaId: parseInt(this.enterprise),
            AreaId: parseInt(this.area),
            PuestoId: parseInt(this.job),
            DocsRequeridos: this.DocsRequeridos,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.message = "Documentos Requeridos";
          this.respuesta = "Los documentos fueron guardados";
          this.show = false;
          this.confirmation = true;
          if (this.area == 0 && this.job == 0) {
            this.documentosAuxiliarEmpresa = this.documentos;
          }          
          if (this.area != 0 && this.job == 0) {
            this.documentosAuxiliarArea = this.documentos;
          }
        })
        .catch((e) => {
          this.show = false;
          this.message = "Documentos Requeridos";
          this.respuesta = "Los documentos no fueron guardados";
          this.confirmation = true;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerPuestos() {
      this.jobs = [];
      this.job = 0;
      this.show = true;
      axios
        .get(Server + "/puestos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (
              value.empresaId == this.enterprise &&
              value.areaReportaId == this.area
            ) {
              this.jobs.push({
                id: value.id,
                nombrePuesto: value.nombrePuesto,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    cambioEmpresa() {
      this.documentos = [];
      this.documentosAuxiliarArea = [];
      this.documentosAuxiliarEmpresa = [];
      this.DocsRequeridos = [];
      this.obtenerAreas();
      //this.listar();
      this.consultaConfig();
    },
    obtenerAreas() {
      this.area = 0;
      this.areas = [];
      this.show = true;
      this.job = 0;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    peticionArea() {
      this.obtenerPuestos();
      this.consultaConfig();
    },
    /*Obtiene todos los catalogos de documentos de la empresa */
    empresasDocs() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/empleadoDocumentos/catalogo-documentos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          response.data.forEach((value, index) => {
            this.catalogoDocumentos.push({
              DocumentoId: value.id,
              descripcionDocumento: value.descripcionDocumento,
              Requerido: false,
            });
          });
          if (this.catalogoDocumentos.length != 0) {
            //this.listar();
            this.consultaConfig();
          }
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    /*Consulta Configuración de documentos requeridos por empresa/area/puesto */
    consultaConfig() {
      this.documentos = [];
      this.DocsRequeridos = [];
      this.show = true;
      axios
        .post(
          Server + "/documentosRequeridos/consulta",
          {
            EmpresaId: parseInt(this.enterprise),
            AreaId: parseInt(this.area),
            SucursalId: parseInt(this.job),
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //Deserializamos el JSON string
          this.DocsRequeridos = JSON.parse(response.data.configuracion);
          //Se recorre los documentos requeridos y se asignan a los que regresa la consulta
          if (this.DocsRequeridos.length != 0) {
            this.catalogoDocumentos.forEach((value, index) => {
              this.DocsRequeridos.forEach((val, index) => {
                if (value.DocumentoId === val.documentoId) {
                  this.documentos.push({
                    DocumentoId: value.DocumentoId,
                    descripcionDocumento: value.descripcionDocumento,
                    Requerido: val.requerido,
                  });
                }
              });
            });
          }
          //Aux para mantener la config previa 1 = config empresa y 2 = config área
          if (response.data.tipoConfiguracion == 1) {
            this.documentosAuxiliarEmpresa = this.documentos;
          } else if (response.data.tipoConfiguracion == 2) {
            this.documentosAuxiliarArea = this.documentos;
          }
          this.show = false;
        })
        .catch((e) => {
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else if (e.response.status == 404) {
            this.DocsRequeridos = [];
            this.message = "Documentos Requeridos";
            //Msg cuando no tiene config por empresa
            if (this.area == 0 && this.job == 0) {
              this.respuesta =
                "No existe configuración previa de documentos para la empresa solicitada, favor de configurarla.";
            }
            //Msg cuando no tiene config por área
            if (this.area != 0 && this.job == 0) {
              this.respuesta =
                "No existe configuración previa del documentos para el área solicitada, favor de configurarla.";
              //Si hay una config previa se asigna
              if (this.documentosAuxiliarEmpresa.length > 0) {
                this.documentos = this.documentosAuxiliarEmpresa;
                this.confirmation = true;
                return;
              }
            }
            //Msg cuando no tiene config por puesto
            if (this.area != 0 && this.job != 0) {
              this.respuesta =
                "No existe configuración previa de documentos para el puesto solicitado, favor de configurarlo.";
              //Si hay una config previa se asigna
              if (this.documentosAuxiliarArea.length > 0) {
                this.documentos = this.documentosAuxiliarArea;
                this.confirmation = true;
                return;
              }
              if (this.documentosAuxiliarEmpresa.length > 0) {
                this.documentos = this.documentosAuxiliarEmpresa;
                this.confirmation = true;
                return;
              }
            }
            //Se llena todos en false cuando no hay config por empresa
            this.catalogoDocumentos.forEach((value, index) => {
              this.documentos.push({
                DocumentoId: value.DocumentoId,
                descripcionDocumento: value.descripcionDocumento,
                Requerido: value.DocumentoId === 18 ? true : false,
              });
            });
            this.documentosAuxiliarArea = [];
            this.documentosAuxiliarEmpresa = [];
            this.confirmation = true;
          }
        });
    },
  },
  created() {
    this.empresasDocs();
    this.obtenerAreas();
  },
};
</script>