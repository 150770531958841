<template>
  <div
    id="documentosEmpleado"
    class="contenedor"
    style="background-color: #fefeff !important"
  >
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-2" v-if="confirmation"
        >DOCUMENTOS REQUERIDOS</v-row
      >
      <v-row no-gutters class="titulo ml-10 mt-2" v-else
        >AUTORIZACIÓN DE DOCUMENTOS</v-row
      >
      <!--DISEÑO CON TARJETAS-->
      <v-row class="mx-7 mt-2" justify="center">
        <v-col
          cols="12"
          class="ml-n4 mt-n2"
          xs="3"
          sm="3"
          md="3"
          lg="3"
          xl="3"
          v-for="(doc, i) in documentosConsulta"
          :key="i"
        >
          <v-card
            color="#f2f2f2"
            class="cardDirectory"
            height="400"
            width="300"
          >
            <v-card-title
              class="orange lighten-1"
              dense
              style="max-height: 40px !important"
              ><span
                class="white--text text-left text mt-n4"
                v-if="
                  doc.descripcionDocumento ==
                  'Justificación de faltas laborales'
                "
                ><h6>
                  {{ doc.descripcionDocumento }}
                </h6></span
              >
              <span class="white--text text-left text mt-n4" v-else
                ><h5>
                  {{ doc.descripcionDocumento }}
                </h5></span
              >
            </v-card-title>
            <v-row class="mx-1" align-content="start">
              <v-col
                class="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                align-self="center"
              >
                <v-card
                  max-height="210"
                  max-width="210"
                  class="ml-4"
                  color="transparent"
                  v-if="doc.urlDocumento"
                >
                  <a
                    :href="doc.urlDocumento"
                    target="_blank"
                    v-if="doc.tipo == 'jpg' && doc.id != 18"
                    ><v-img
                      :src="doc.urlDocumento"
                      height="210px"
                      width="210px"
                      max-height="210px"
                      max-width="210px"
                      v-if="doc.tipo == 'jpg' && doc.id != 18"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="orange"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <!-- <label v-else-if="doc.tipo == 'pdf'">{{doc.descripcionDocumento}}</label> -->
                  </a>
                  <a
                    :href="doc.urlDocumento"
                    target="_blank"
                    v-if="doc.tipo == 'pdf' && doc.id != 18"
                  >
                    <h4>Ver {{ doc.descripcionDocumento.toUpperCase() }}</h4>
                  </a>
                  <ul v-if="doc.id == 18 && otrosDocs.length > 0" class="izq">
                    <li v-for="(otro, index) in otrosDocs" :key="index">
                      <a :href="otro.urlDocumento" target="_blank"
                        ><h5>
                          {{ otro.descripcionDocumento.substring(0, 25) }}
                        </h5></a
                      >
                    </li>
                  </ul>
                </v-card>
                <input
                  type="file"
                  name="just"
                  id="just"
                  class="inputs mt-3 mb-n2"
                  style="width: 75% !important"
                  @change="
                    tipoDocPost = doc.id;
                    onFileChange($event, doc);
                  "
                  accept=".jpg,.png,.jpeg,.pdf"
                  v-bind="doc.id == 18 ? { multiple: true } : ''"
                />
              </v-col>
              <v-col
                class="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                align-self="start"
              >
                <v-switch
                  v-model="doc.autorizadoPorRH"
                  color="orange"
                  inset
                  :label="`Autorizado: ${doc.autorizadoPorRH ? 'Sí' : 'No'}`"
                  @change="
                    actualizaEstatus(
                      doc.empleadoDocumentosId,
                      doc.autorizadoPorRH
                    )
                  "
                  :class="doc.catalogoDocumentosId == 19 ? 'mt-n12' : ''"
                  v-if="doc.empleadoDocumentosId != null && doc.id != 18"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mx-12 mt-5" v-if="confirmation == true">
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-card>
            <v-card-text class="text-center">
              <span class="orange white--text px-2 py-1">{{ respuesta }}</span>
            </v-card-text>
            <v-card-text
              class="text-center"
              v-if="documentosConsulta.length == 0"
            >
              <button class="botonAmarilloDerecho" @click="irConfiguracion()">
                Ir
              </button>
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
    </v-container>
    <!--Confirmación de datos-->
    <v-dialog v-model="alert" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="alert = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      alert: false,
      respuesta: "",
      message: "",
      confirmation: false,
      idEmpleado: localStorage.employeeNumber,
      documentos: [],
      documentosFinales: [],
      catalogoDocumentos: [],
      documentosConsulta: [],
      DocsRequeridos: [],
      apiDocumentos: [],
      job: localStorage.puesto,
      enterprise: localStorage.empresa,
      area: localStorage.area,
      url: "",
      justificante: "",
      tipoDocPost: 0,
      banderaConfigEncontrado: false,
      mimeType: null,
      otrosDocs: [],
      nuevosDocs: [],
      formData: new FormData(),
    };
  },
  methods: {
    //Va a la configuracion de documentos de la empresa
    irConfiguracion() {
      this.$router.push("/vaultDocuments");
    },
    //actualiza el estatus del documento, si esta autorizado o no
    actualizaEstatus(idDocumentoEmpleado, estatus) {
      this.show = true;
      axios
        .put(
          Server + "/empleadoDocumentos",
          {
            EmpleadoDocumentosId: idDocumentoEmpleado,
            Autorizacion: estatus,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },

    onFileChange(evt, doc) {
      var listFiles = evt.target.files;
      var file = evt.target.files[0];
      this.url = URL.createObjectURL(file);
      var fileBase = evt.target.files[0];
      this.mimeType = fileBase.type;
      var reader = new FileReader();
      reader.onloadend = function () {
        sessionStorage.setItem("base64img1", reader.result);
      };
      reader.readAsDataURL(fileBase);
      this.justificante = event.target.files[0];
      if (this.tipoDocPost == 18) {
        for (let i = 0; i < listFiles.length; i++) {
          this.formData = new FormData();
          this.formData.append("EmpleadoId", this.idEmpleado);
          this.formData.append("CatalogoDocumentoId", this.tipoDocPost);
          this.formData.append("files", listFiles[i], listFiles[i].name);
          this.cargarOtrosDocs(this.formData);
        }
      } else {
        setTimeout(() => {
          this.carga();
        }, 1000);
      }
    },
    carga() {
      this.justificante = sessionStorage.getItem("base64img1");
      if (this.justificante != null && this.justificante != "") {
        var indexCorte = this.justificante.indexOf(",");
        this.justificante = this.justificante.substring(indexCorte + 1);
      }
      this.show = true;
      axios
        .post(
          Server + "/empleadoDocumentos?tipoFile=" + this.mimeType,
          {
            EmpleadoId: this.idEmpleado,
            Imagen: this.justificante,
            TipoDocumento: this.tipoDocPost,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.message = "Confirmación";
          this.respuesta = "El documento fue guardado con éxito.";
          this.show = false;
          sessionStorage.clear();
          this.actualizaImagen();
          this.alert = true;
        })
        .catch((e) => {
          console.log("error carga", e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.message = "Error al cargar el documento";
            this.respuesta = "El documento no fue almacenada.";
            this.alert = true;
          }
        });
    },
    consultaCatalogo() {
      this.show = true;
      axios
        .get(Server + "/empleadoDocumentos/catalogo-documentos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          response.data.forEach((value, index) => {
            this.catalogoDocumentos.push({
              DocumentoId: value.id,
              descripcionDocumento: value.descripcionDocumento,
              tituloDocumento: value.tituloDocumento,
            });
          });
          this.obtenerConfig();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerConfig() {
      var areaAuxId = parseInt(this.area);
      var puestoAuxId = parseInt(this.job);
      this.config(areaAuxId, puestoAuxId);
      setTimeout(() => {
        console.log("espera 1");
        if (this.banderaConfigEncontrado == false) {
          puestoAuxId = 0;
          this.config(areaAuxId, puestoAuxId);
          setTimeout(() => {
            console.log("espera 2");
            if (this.banderaConfigEncontrado == false) {
              puestoAuxId = 0;
              areaAuxId = 0;
              this.config(areaAuxId, puestoAuxId);
              setTimeout(() => {
                this.show = false;
                if (this.banderaConfigEncontrado == false) {
                  this.respuesta =
                    "No existe configuración previa de documentos para la empresa solicitada, favor de configurarla.";
                  this.confirmation = true;
                }
              }, 1500);
            } else {
              this.show = false;
              return;
            }
          }, 1500);
        } else {
          this.show = false;
          return;
        }
      }, 1500);
    },
    consultaDocumento(doc) {
      axios
        .get(
          Server +
            "/empleadoDocumentos/por-tipo/" +
            this.idEmpleado +
            "/" +
            doc.DocumentoId,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          var splitUrl = response.data.urlDocumento.split("/");
          splitUrl = splitUrl[splitUrl.length - 1];
          var indexExtension = splitUrl.split(".");
          var extension = indexExtension[indexExtension.length - 1];
          this.documentosConsulta.push({
            id: doc.DocumentoId,
            urlDocumento:
              response.data.urlDocumento +
              "?rand=" +
              Math.random().toPrecision(4).toString(),
            autorizadoPorRH: response.data.autorizadoPorRH,
            descripcionDocumento: doc.descripcionDocumento,
            empleadoDocumentosId: response.data.id,
            tipo: extension,
          });
          return;
        })
        .catch((e) => {
          //console.log("no tiene documento", e);
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.documentosConsulta.push({
              id: doc.DocumentoId,
              urlDocumento: null,
              autorizadoPorRH: false,
              descripcionDocumento: doc.descripcionDocumento,
              empleadoDocumentosId: null,
              tipo: null,
            });
          }
        });
    },
    config(areaAuxId, puestoAuxId) {
      this.DocsRequeridos = [];
      this.documentosConsulta = [];
      this.show = true;
      axios
        .post(
          Server + "/documentosRequeridos/consulta",
          {
            EmpresaId: parseInt(this.enterprise),
            AreaId: areaAuxId,
            SucursalId: puestoAuxId,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.DocsRequeridos = JSON.parse(response.data.configuracion);
          this.catalogoDocumentos.forEach((doc, index) => {
            this.DocsRequeridos.forEach((document, i) => {
              if (
                doc.DocumentoId == document.documentoId &&
                document.requerido && doc.DocumentoId != 20
              ) {
                this.consultaDocumento(doc);
              }
            });
            if (doc.DocumentoId == 18) {
              this.obtenerOtrosDocs();
            }
          });
          this.banderaConfigEncontrado = true;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            console.log("No hay config: ");
            return false;
          }
        });
    },
    actualizaImagen() {
      axios
        .get(
          Server +
            "/empleadoDocumentos/por-tipo/" +
            this.idEmpleado +
            "/" +
            this.tipoDocPost,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          var splitUrl = response.data.urlDocumento.split("/");
          splitUrl = splitUrl[splitUrl.length - 1];
          var indexExtension = splitUrl.split(".");
          var extension = indexExtension[indexExtension.length - 1];
          this.documentosConsulta.forEach((element) => {
            if (element.id == response.data.catalogoDocumentosId) {
              element.urlDocumento =
                response.data.urlDocumento +
                "?rand=" +
                Math.random().toPrecision(4).toString();
              element.autorizadoPorRH = response.data.autorizadoPorRH;
              element.empleadoDocumentosId = response.data.id;
              element.tipo = extension;
              return;
            }
          });
        })
        .catch((e) => {
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
          }
        });
    },
    obtenerOtrosDocs() {
      console.log("listado");
      axios
        .get(
          Server + "/empleadoDocumentos/tipo-otros/" + this.idEmpleado + "/18",
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.otrosDocs = [];
          if (response.data.length > 0) {
            response.data.forEach((element) => {
              var splitUrl = element.urlDocumento.split("/");
              splitUrl = splitUrl[splitUrl.length - 1];
              var indexExtension = splitUrl.split(".");
              var extension = indexExtension[indexExtension.length - 1];
              this.otrosDocs.push({
                id: element.catalogoDocumentosId,
                urlDocumento:
                  element.urlDocumento +
                  "?rand=" +
                  Math.random().toPrecision(4).toString(),
                autorizadoPorRH: element.autorizadoPorRH,
                descripcionDocumento: indexExtension[0],
                empleadoDocumentosId: element.id,
                tipo: extension,
              });
            });
          }
          console.log(this.otrosDocs);
        })
        .catch((e) => {
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
          }
        });
    },
    cargarOtrosDocs(data) {
      this.show = true;
      axios
        .post(Server + "/empleadoDocumentos/carga-docs", data, {
          headers: {
            Authorization: localStorage.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          this.message = "Confirmación";
          this.respuesta = "El documento fue guardado con éxito.";
          this.show = false;
          sessionStorage.clear();
          //this.obtenerOtrosDocs();
          this.banderaConfigEncontrado = false;
          this.documentosConsulta = [];
          this.otrosDocs = [];
          this.obtenerConfig();
          this.alert = true;
        })
        .catch((e) => {
          console.log("error carga", e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.message = "Error al cargar el documento";
            this.respuesta = "El documento no fue almacenada.";
            this.alert = true;
          }
        });
    },
  },
  created() {
    this.consultaCatalogo();
  },
};
</script>
<style scoped>
.v-responsive__sizer {
  padding-bottom: 0% !important;
}
.izq {
  display: inline-block;
  text-align: left;
}
</style>