var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contenedor",staticStyle:{"background-color":"#fefeff !important"},attrs:{"id":"documentosEmpleado"}},[_c('loader',{attrs:{"show":_vm.show}}),_c('sesion',{attrs:{"expiration":_vm.expiration}}),_c('Sidebar'),_c('v-container',{staticClass:"base text-center",attrs:{"fluid":""}},[(_vm.confirmation)?_c('v-row',{staticClass:"titulo ml-10 mt-2",attrs:{"no-gutters":""}},[_vm._v("DOCUMENTOS REQUERIDOS")]):_c('v-row',{staticClass:"titulo ml-10 mt-2",attrs:{"no-gutters":""}},[_vm._v("AUTORIZACIÓN DE DOCUMENTOS")]),_c('v-row',{staticClass:"mx-7 mt-2",attrs:{"justify":"center"}},_vm._l((_vm.documentosConsulta),function(doc,i){return _c('v-col',{key:i,staticClass:"ml-n4 mt-n2",attrs:{"cols":"12","xs":"3","sm":"3","md":"3","lg":"3","xl":"3"}},[_c('v-card',{staticClass:"cardDirectory",attrs:{"color":"#f2f2f2","height":"400","width":"300"}},[_c('v-card-title',{staticClass:"orange lighten-1",staticStyle:{"max-height":"40px !important"},attrs:{"dense":""}},[(
                doc.descripcionDocumento ==
                'Justificación de faltas laborales'
              )?_c('span',{staticClass:"white--text text-left text mt-n4"},[_c('h6',[_vm._v(" "+_vm._s(doc.descripcionDocumento)+" ")])]):_c('span',{staticClass:"white--text text-left text mt-n4"},[_c('h5',[_vm._v(" "+_vm._s(doc.descripcionDocumento)+" ")])])]),_c('v-row',{staticClass:"mx-1",attrs:{"align-content":"start"}},[_c('v-col',{staticClass:"12",attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"12","align-self":"center"}},[(doc.urlDocumento)?_c('v-card',{staticClass:"ml-4",attrs:{"max-height":"210","max-width":"210","color":"transparent"}},[(doc.tipo == 'jpg' && doc.id != 18)?_c('a',{attrs:{"href":doc.urlDocumento,"target":"_blank"}},[(doc.tipo == 'jpg' && doc.id != 18)?_c('v-img',{attrs:{"src":doc.urlDocumento,"height":"210px","width":"210px","max-height":"210px","max-width":"210px"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"orange"}})],1)]},proxy:true}],null,true)}):_vm._e()],1):_vm._e(),(doc.tipo == 'pdf' && doc.id != 18)?_c('a',{attrs:{"href":doc.urlDocumento,"target":"_blank"}},[_c('h4',[_vm._v("Ver "+_vm._s(doc.descripcionDocumento.toUpperCase()))])]):_vm._e(),(doc.id == 18 && _vm.otrosDocs.length > 0)?_c('ul',{staticClass:"izq"},_vm._l((_vm.otrosDocs),function(otro,index){return _c('li',{key:index},[_c('a',{attrs:{"href":otro.urlDocumento,"target":"_blank"}},[_c('h5',[_vm._v(" "+_vm._s(otro.descripcionDocumento.substring(0, 25))+" ")])])])}),0):_vm._e()]):_vm._e(),_c('input',_vm._b({staticClass:"inputs mt-3 mb-n2",staticStyle:{"width":"75% !important"},attrs:{"type":"file","name":"just","id":"just","accept":".jpg,.png,.jpeg,.pdf"},on:{"change":function($event){_vm.tipoDocPost = doc.id;
                  _vm.onFileChange($event, doc);}}},'input',doc.id == 18 ? { multiple: true } : '',false))],1),_c('v-col',{staticClass:"12",attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"12","align-self":"start"}},[(doc.empleadoDocumentosId != null && doc.id != 18)?_c('v-switch',{class:doc.catalogoDocumentosId == 19 ? 'mt-n12' : '',attrs:{"color":"orange","inset":"","label":`Autorizado: ${doc.autorizadoPorRH ? 'Sí' : 'No'}`},on:{"change":function($event){return _vm.actualizaEstatus(
                    doc.empleadoDocumentosId,
                    doc.autorizadoPorRH
                  )}},model:{value:(doc.autorizadoPorRH),callback:function ($$v) {_vm.$set(doc, "autorizadoPorRH", $$v)},expression:"doc.autorizadoPorRH"}}):_vm._e()],1)],1)],1)],1)}),1),(_vm.confirmation == true)?_c('v-row',{staticClass:"mx-12 mt-5"},[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('v-card',[_c('v-card-text',{staticClass:"text-center"},[_c('span',{staticClass:"orange white--text px-2 py-1"},[_vm._v(_vm._s(_vm.respuesta))])]),(_vm.documentosConsulta.length == 0)?_c('v-card-text',{staticClass:"text-center"},[_c('button',{staticClass:"botonAmarilloDerecho",on:{"click":function($event){return _vm.irConfiguracion()}}},[_vm._v(" Ir ")])]):_vm._e()],1)],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_c('v-card',[_c('v-card-title',{staticClass:"orange white--text"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.message))])]),_c('v-card-text',[_c('br'),_c('h3',{staticClass:"text-lg-center text-sm-center text-md-center"},[_vm._v(" "+_vm._s(_vm.respuesta)+" ")]),_c('br')]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){_vm.alert = false}}},[_vm._v("OK")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }